




















































import {
  Component, Vue, PropSync, Prop,
} from 'vue-property-decorator';
import ModalRequestNewLinkToCreatePasswordViewModel
  from '@/vue-app/view-models/components/on-boarding/alerts/modal-request-new-link-to-create-password-view-model';

@Component({ name: 'ModalRequestNewLinkToCreatePassword' })
export default class ModalRequestNewLinkToCreatePassword extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @Prop(String)
  token!: string;

  request_new_link_view_model = Vue.observable(new ModalRequestNewLinkToCreatePasswordViewModel());

  async mounted() {
    await this.request_new_link_view_model.initialize(this.token);
  }
}
