import TYPES from '@/types';

// Application
import RequestNewLinkToRegisterCommand
  from '@/modules/onboarding/on-boarding-steps/application/commands/request-new-link-to-register-command';

// Domain
import { RequestNewLinkToRegisterDto }
  from '@/modules/onboarding/on-boarding-steps/domain/dtos/request-new-link-to-register-dto';
import Inject from '@/modules/shared/domain/dependency_injection/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class ModalRequestNewLinkToCreatePasswordViewModel {
  @Inject(TYPES.REQUEST_NEW_LINK_TO_REGISTER_COMMAND)
  private readonly request_new_link_to_register_cmd!: RequestNewLinkToRegisterCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly notifier!: MessageNotifier;

  request_new_link_to_register_dto: RequestNewLinkToRegisterDto = {
    customer_id: '',
  };

  is_btn_disabled = true;

  is_btn_loading = false;

  initialize = async (token: string) => {
    if (token) {
      this.unserializeToken(token);
      this.is_btn_disabled = false;
    }
  }

  unserializeToken = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const json = JSON.parse(window.atob(base64));
    this.request_new_link_to_register_dto.customer_id = json.customer_id;
  }

  requestNewLinkToRegister = async () => {
    try {
      this.is_btn_loading = true;
      await this.request_new_link_to_register_cmd.execute(this.request_new_link_to_register_dto);
      this.notifier.showInfoNotification('Se ha solicitado un nuevo enlace, su promotor le hará llegar un nuevo enlace por email');
      this.is_btn_loading = false;
      this.is_btn_disabled = true;
    } catch (err) {
      const error = JSON.parse(err);
      if (error.message_error === 'User already request new link to register') {
        this.notifier.showInfoNotification('Ya solicitó un nuevo enlace, su promotor le hará llegar un nuevo enlace por email');
      } else {
        this.notifier.showErrorNotification('Ocurrió un error al solicitar un nuevo enlace, contacte a su promotor');
      }
      this.is_btn_disabled = true;
      this.is_btn_loading = false;
    }
  }
}
